a:visited {
  color: gray;
}
a:active {
  color: black;
}
a {
  color: #33a;
}

* {
  font-family: "Arial";
}
.card {
  width: 360px;
  height: 240px;
  margin: 1rem;
  border: 1px solid silver;
  position: relative;
}

.card .title {
  font-size: 1.6em;
  color: #33a;
  background-color: #f3f3f3;
  padding: 0.25rem;
  padding-left: 1rem;
  position: absolute;
  left: 50px;
  top: 10px;
  width: 280px;
  border-radius: 15px;
}

.card .sentence {
  font-size: 1.1em;
  padding: 0.25rem;
  padding-left: 1rem;
  position: absolute;
  left: 30px;
  top: 80px;
  width: 300px;
  height: 100px;
}

.card .number {
  position: absolute;
  right: 25px;
  bottom: 20px;
  font-size: 0.8em;
}

.copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 20px #ddd;
  background: #ddd;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
}
.copyright p {
  text-align: right;
  padding: 0.4em;
}
